@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Overpass:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* theme */
@import "./assets/primereact-themes/theme.css";

/* core */
@import "primereact/resources/primereact.min.css";

:root {
   --font-family: "Inter", sans-serif !important;
}

body {
   font-family: "Inter", sans-serif;
   @apply transition;
}
a {
   @apply text-primaryLight cursor-pointer;
}

.p-invalid {
   @apply text-danger bg-dangerLight;
}
.ar {
   direction: rtl;
}

/* forms styles */

label {
   @apply text-primary;
}
input:not([type="radio"]),
select,
textarea {
   @apply text-black/80  focus:text-primary disabled:bg-gray-200 disabled:!opacity-80 !border-secondary;
}

.p-inputtext:enabled:focus {
   @apply border-primary shadow-primary shadow;
}

.recap input,
.recap select {
   @apply appearance-none py-1.5;
}
.has-error,
input.has-error,
select.has-error {
   @apply !bg-danger/[.16] !border-danger !shadow-danger !text-danger;
}

.auth input.has-error {
   @apply !bg-danger/70 !text-white/80;
}
.auth input.has-error::placeholder {
   @apply text-white;
}
.auth .error-message {
   display: none;
}

/* end of forms styles */

/* buttons styles */

button {
   @apply disabled:cursor-not-allowed hover:opacity-80 transition disabled:opacity-75;
}
button.basic {
   @apply px-10 py-1 rounded;
}
button.primary {
   @apply bg-primary text-white;
}
button.danger {
   @apply bg-danger text-white;
}
button.outline-primary {
   @apply border border-primary hover:bg-primary text-primary hover:text-white;
}
/* end of buttons styles */

/* other styles */
* {
   @apply text-primary;
}
.container {
   max-width: 1100px;
}
.recap .label-star {
   display: none;
}
.recap {
   box-shadow: 0 3px 10px -5px;
}
.navbar-link {
   @apply hover:text-white/75 px-5 after:content-[''] after:w-[1px] after:h-14 after:bg-white/25 after:inline-block after:absolute after:-top-4 after:right-0;
}
.like-butotn-title {
   @apply text-white bg-primary rounded-b-xl px-4 py-2 inline-block;
}
/* end of other styles */

/* titles styles */
.title1 {
   @apply lg:text-4xl md:font-light;
}
/* end of titles styles */
